import React, { useReducer, useState } from "react";
import styled from "styled-components";

import {
	TextField,
	Button,
	Dialog,
	DialogContent,
	DialogActions
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import firebase from "firebase";

const Container = styled.div`
	height: 100vh;
	width: 100%;

	background: linear-gradient(
		0deg,
		rgba(0, 0, 0, 1) 0%,
		rgba(196, 45, 62, 1) 100%
	);

	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;

	img {
		width: 200px;
		height: auto;
		margin-bottom: 32px;
	}

	.login-container {
		position: relative;
		background: #f0f0f0;

		border-radius: 4px;
		box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);

		display: flex;
		flex-direction: column;
		align-items: center;

		padding: 32px;
	}

	.button-back {
		position: absolute;
		left: 0;
	}

	form {
		display: flex;
		flex-direction: column;
		align-items: center;

		width: 100%;

		& > * {
			margin-bottom: 16px;
		}

		& > input {
			width: 100%;
		}

		.button {
			max-width: 400px;
			width: 100%;
		}

		.input {
			/* min-width: 100%; */
			max-width: 300px;
			width: 200%;
		}
	}
`;

const initialLoginState = {
	email: "",
	password: "",
	err: ""
};

function resetReducer(state, action) {
	switch (action.type) {
		case "change":
			return updateValue(state, action.key, action.value);
		case "reset":
			return initialLoginState;
		default:
			return state;
	}
}

function updateValue(state, key, value) {
	return {
		...state,
		[key]: value
	};
}

export const ForgotPassword = (props) => {
	const [state, dispatch] = useReducer(
		resetReducer,
		initialLoginState
	);

	const [isDialogOpen, setIsDialogOpen] = useState(false);

	const handleChange = (e) => {
		e.preventDefault();
		let key = e.target.name;
		let value = e.target.value;

		if (state.err)
			dispatch({ type: "change", key: "err", value: "" });

		dispatch({ type: "change", key, value });
	};

	const resetMyPassword = () => {
		const reactappwebdomian = process.env.REACT_APP_WEB_DOMAIN;
		if (state.email && state.email !== "") {
			firebase
				.auth()
				.sendPasswordResetEmail(state.email)
				.then(() => {
					dispatch({ type: "change", key: "email", value: "" });
					dispatch({
						type: "change",
						key: "err",
						value: "We have sent you a link to reset your password. Kindly check your email."
					});
					setIsDialogOpen(true);
				})
				.catch((error) => {
					const errorMessage = error.message;
					dispatch({
						type: "change",
						key: "err",
						value: errorMessage
					});
					setIsDialogOpen(true);
				});
		}
	};

	return (
		<Container>
			<div className="login-container">
				<Button
					className="button-back"
					onClick={() => props.history.goBack()}
				>
					<ArrowBackIcon />
				</Button>
				<img alt="Bar Hopper" src="/logo2.png" />

				<form
					noValidate
					onSubmit={() => resetMyPassword}
					style={{ width: "300px" }}
				>
					<TextField
						className="input"
						label="Email"
						variant="outlined"
						name="email"
						value={state.email || null}
						onChange={handleChange}
					/>
					<Button
						className="button"
						color="primary"
						variant="outlined"
						onClick={() => resetMyPassword()}
					>
						Reset my password
					</Button>
				</form>
			</div>
			<Dialog open={isDialogOpen}>
				<DialogContent>{state.err}</DialogContent>
				<DialogActions>
					<Button
						color="primary"
						variant="contained"
						onClick={() => setIsDialogOpen(false)}
					>
						OK
					</Button>
				</DialogActions>
			</Dialog>
		</Container>
	);
};
