import React, { useCallback, useEffect, useState } from "react";
import { useHistory /*, useLocation*/ } from "react-router-dom";
import styled from "styled-components";

import {
	Button,
	InputAdornment,
	Chip,
	TextField
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Search } from "@mui/icons-material";
//import { useParams } from "react-router-dom";
import {
	usePromotions,
	usePromotionUtils
} from "../actions/promotions";
import { firestore } from "../services/firebase";
import { THEME_MAP } from "../theme";
import dayjs from "dayjs";

export function Promotions(props) {
	const history = useHistory();
	const { getResults } = usePromotions();
	const [promotions, setPromotions] = useState([]);
	const [isBarPromotions, setIsBarPromotions] = useState(false);
	const [pagePromotions, setPagePromotions] = useState([]);
	const { clearSelectedPromotion, deletePromotion } =
		usePromotionUtils();
	const promotionsRef = firestore.collection("Promotions");
	const [showDeleteAlert, setShowDeleteAlert] = useState();
	const getAllPromotions = useCallback(
		async (barId) => {
			const promotions_ = await getResults(promotionsRef, barId);
			setPromotions(promotions_);
			setPagePromotions(promotions_);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[promotionsRef]
	);

	useEffect(() => {
		(async () => {
			const barId = props.match.params.id;
			if (barId && barId.length > 3) {
				setIsBarPromotions(true);
			}
			getAllPromotions(barId);
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.match.params.id]);

	const navigateToAddPromotion = () => {
		clearSelectedPromotion();
		history.push("/promotion/new");
	};

	const handleSearch = (e) => {
		setPagePromotions(
			promotions.filter(
				(b) =>
					b.promotionName
						.toLowerCase()
						.indexOf(e.target.value.toLowerCase()) >= 0 ||
					b.bar?.barName
						?.toLowerCase()
						.indexOf(e.target.value.toLowerCase()) >= 0
			)
		);
	};

	const removePromotion = async () => {
		await deletePromotion(showDeleteAlert);
		setShowDeleteAlert("");
		getAllPromotions(props.match.params.id);
	};

	const dialogView = (
		<Dialog
			open={
				showDeleteAlert && showDeleteAlert.length > 0
					? true
					: false
			}
			onClose={() => setShowDeleteAlert("")}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title">
				{"Are you sure?"}
			</DialogTitle>
			<DialogContent>
				<DialogContentText id="alert-dialog-description">
					Deleting this promotion will result in the loss of any
					associated payment. Please consider reaching out to
					support@gobarhopper.com for support before confirming.
					Are you sure you want to proceed?
					<br />
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={() => setShowDeleteAlert("")}
					color="primary"
				>
					Cancel
				</Button>
				<Button
					onClick={() => removePromotion()}
					color="primary"
					autoFocus
				>
					Delete
				</Button>
			</DialogActions>
		</Dialog>
	);

	return (
		<Container>
			<div className="filters">
				<TextField
					className="input"
					placeholder="Filter"
					variant="outlined"
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<Search>Filled</Search>
							</InputAdornment>
						)
					}}
					onChange={handleSearch}
				/>
				{isBarPromotions && (
					<Button
						color="primary"
						variant="contained"
						onClick={navigateToAddPromotion}
					>
						Create Promotion
					</Button>
				)}
			</div>
			<div className="promotion-container">
				{pagePromotions?.map((snapshot) => (
					<PromotionListItem
						key={snapshot.id}
						onDelete={(id) => setShowDeleteAlert(id)}
						promotionSnapshot={snapshot}
					/>
				))}
				{pagePromotions.length === 0 && (
					<NoResultsContainer>
						<span className="promotionName">
							No promotions created
						</span>
					</NoResultsContainer>
				)}
			</div>

			{dialogView}
		</Container>
	);
}
const Container = styled.div`
	height: 100%;
	width: 100%;

	overflow: auto;

	padding: 24px;

	.input {
		background: #fff;
	}

	.filters {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 16px;
	}

	.promotion-container {
		width: 100%;
		min-height: 100px;
		border-radius: 4px;
		border: 1px solid ${THEME_MAP["color-basic-400"]};
		background: ${THEME_MAP["color-basic-100"]};

		padding: 8px 0;

		margin-bottom: 16px;

		max-height: calc(100% - 125px);
		overflow-y: scroll;
	}
`;

function PromotionListItem({
	promotionSnapshot,
	//barSnapshot,
	onDelete
}) {
	const [currentRef] = useState(promotionSnapshot.ref);
	//const [bar, setBar] = useState(barSnapshot?.data());
	const [promotion, setPromo] = useState(promotionSnapshot);
	const history = useHistory();
	//const { id: promotionId } = useParams();
	const { setSelectedPromotion } = usePromotionUtils();

	useEffect(() => {
		if (currentRef) {
			return currentRef.onSnapshot((snap) => {
				setPromo({ ...snap.data(), id: snap.id });
				//setBar(snap.data());
			});
		}
	}, [currentRef]);

	const handleClick = () => {
		setSelectedPromotion(promotionSnapshot);
		history.push(`/promotion/${promotionSnapshot.id}`);
	};

	if (
		!promotion.promotionName ||
		!promotion.bar ||
		!promotion.bar.barName
	) {
		return <></>;
	}
	const now = dayjs();
	const promoIsActive = dayjs(
		new Date(
			promotion.promotionEndingHours.seconds * 1000 +
				promotion.promotionEndingHours.nanoseconds / 1000000
		)
	).isAfter(now);

	return (
		<>
			<PromoItem>
				<div
					style={{
						display: "inline-block",
						width: "calc(100% - 90px)"
					}}
					onClick={handleClick}
				>
					<span className="promotionName">
						{promotion?.promotionName || "Promotion Name"}
					</span>
					<span className="barName">
						{promotion?.bar?.barName || "Bar Name"}
					</span>

					{promoIsActive && (
						<Chip
							style={{
								fontSize: "12px",
								width: "auto",
								height: "auto",
								background: "#C42D3E",
								color: "white",
								marginLeft: "10px"
							}}
							label={"Active"}
							variant="outlined"
						/>
					)}
				</div>
				<Button
					onClick={(e) => {
						e.stopPropagation();
						e.preventDefault();
						onDelete(promotionSnapshot.id);
					}}
					style={{ float: "right" }}
					aria-label="remove permissions"
					variant="outlined"
				>
					DELETE
				</Button>
			</PromoItem>
		</>
	);
}

const PromoItem = styled.div`
	padding: 8px 24px;
	width: 100%;
	justify-content: space-between;
	margin: 4px 0;

	&:hover {
		background: ${THEME_MAP["color-primary-transparent-100"]};
		cursor: pointer;
	}

	span {
		display: block;

		&.promotionName {
			color: ${THEME_MAP["color-primary-700"]};
			font-size: 1.2rem;
			/* font-weight: bold; */
		}

		&.barName {
			color: ${THEME_MAP["color-basic-600"]};
		}
	}
`;

const NoResultsContainer = styled.div`
	padding: 8px 24px;
`;
